import React, { useState } from "react";
import Navigation from "../Navigation";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Button, Container, Row, Col } from "reactstrap";
import Headroom from "react-headroom";
import { useMediaQuery } from "react-responsive";
import Link from "gatsby-link";

import "./style.scss";

const Header = () => {
  const [navOpen, toggleNav] = useState(false);

  return (
    <header id="header">
      <div className="header-info">
        <Container>
          <Row>
            <Col md="6" className="info">
              <span>Atlanta, Georgia</span>
              <span className="d-xl-inline mx-3">|</span>
              <span>October 13-16, 2024</span>
              <span className="d-xl-inline mx-3">|</span>
              <span>
                <a
                  href="https://twitter.com/hashtag/XylemReach?src=hashtag_click"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  #xylemreach
                </a>
              </span>
            </Col>

            <Col md="6" className="cta-buttons">
              <Link
                className="primary-cta"
                to="https://cvent.me/z74Vkl?RefId=reach24-registration"
                target="_blank"
              >
                Register Now
              </Link>
              {/* <Link className="primary-cta ml-3" href="https://book.passkey.com/go/Xylem23" target="_blank">Book a Room</Link> */}
              <Link className="tertiary-cta ml-3" to="/attend/updates">
                Sign Up for Updates
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="nav-wrapper">
        <Container>
          <Row>
            <Col xs="auto">
              <AniLink
                className="logo-link"
                cover
                to="/"
                bg="
                url(/images/XylemReach22_Logo_Wh_RGB.svg)
                center / 200px /* position / size */
                no-repeat                /* repeat */
                fixed                    /* attachment */
                padding-box              /* origin */
                content-box              /* clip */
                #0085AD                    /* color */
                "
                duration={1.5}
              >
                <div className="logo"></div>
              </AniLink>
            </Col>
            <Col xs="auto" className="d-flex ml-nav">
              <button
                className="nav-toggle"
                type="button"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => toggleNav(!navOpen)}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <Navigation
                navOpen={navOpen}
                navHandler={() => toggleNav(!navOpen)}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

const HeaderHelper = () => {
  const isDesktop = useMediaQuery({
    query: `(min-width: 992px)`,
  });

  if (isDesktop) {
    return (
      <Headroom>
        <Header />
      </Headroom>
    );
  } else {
    return <Header />;
  }
};

export default HeaderHelper;
