import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitterSquare,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

let thisYear = new Date().getFullYear();
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center social mt-5">
            <a
              href="https://www.facebook.com/SensusGlobal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://twitter.com/SensusGlobal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitterSquare} />
            </a>
            <a
              href="https://www.linkedin.com/company/sensus"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a
              href="https://www.youtube.com/c/SensusGlobal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
          <div className="col-12 text-center copy mt-3">
            <a
              className="footer-link"
              href="https://blog.sensus.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sensus Blog
            </a>
            <p className="footer-bar"> | </p>
            <a className="footer-link" href="mailto:reach.team@xylem.com">
              Contact Us
            </a>
          </div>
          <div className="col-12 copy mt-3">
            <p className="footer-copy disclaimer">
              DISCLAIMER: By registering for the 2024 Xylem Reach Conference,
              you consent to your voice, name and/or likeness being used,
              without compensation, for any lawful purpose, including publicity,
              illustration, advertising, and web or social media content
              promoting the 2024 event and that for subsequent years. You
              release Sensus and Xylem, their successors, assigns and licensees
              from any liability whatsoever of any nature related to their use
              of your voice, name and/or likeness.
            </p>
          </div>
          <div className="col-12 text-center copy mt-3 mb-5">
            <p className="footer-copy">
              ©{thisYear} Xylem. All rights reserved.
            </p>{" "}
            <p className="footer-bar first"> | </p>
            <a
              className="footer-link"
              href="https://www.xylem.com/en-us/support/terms-and-conditions/"
            >
              Terms & Conditions
            </a>
            <p className="footer-bar"> | </p>
            <a
              className="footer-link"
              href="https://www.xylem.com/en-us/support/privacy/"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
