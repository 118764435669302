// import { Link } from "gatsby"
import React, { useState } from "react";
import Link from "gatsby-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {
  Nav,
  NavItem,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-regular-svg-icons";

const DropdownElement = ({ children }) => <div>{children}</div>;

const Navigation = (props) => {
  const [isOpen, updateIsOpen] = useState("");

  return (
    <nav
      className={
        props.navOpen === true ? "navigation mobile-active" : "navigation"
      }
    >
      <FontAwesomeIcon
        className="close-icon"
        icon={faWindowClose}
        onClick={() => props.navHandler()}
      />
      <div className="navigation-content">
        <Nav className="nav-items">
          <NavItem>
            <AniLink
              className="nav-link"
              cover
              to="/"
              bg="
            url(/images/XylemReach22_Logo_Wh_RGB.svg)
            center / 200px
            no-repeat               
            fixed                    
            padding-box              
            content-box              
            #0085AD                    
            "
              duration={1.5}
            >
              Home
            </AniLink>
          </NavItem>

          {/* Attend */}
          <Dropdown
            nav
            inNavbar
            onMouseOver={() => updateIsOpen("attend")}
            onFocus={() => updateIsOpen("attend")}
            onMouseLeave={() => updateIsOpen("")}
            onBlur={() => updateIsOpen("")}
            toggle={() => updateIsOpen(isOpen === "attend" ? "" : "attend")}
            isOpen={isOpen === "attend" ? true : false}
          >
            <DropdownToggle nav tag={DropdownElement} className="parent">
              <AniLink
                className="nav-link"
                cover
                to="/attend/"
                bg="
              url(/images/XylemReach22_Logo_Wh_RGB.svg)
              center / 200px 
              no-repeat                
              fixed                    
              padding-box             
              content-box              
              #0085AD                    
              "
                duration={1.5}
              >
                Attend
              </AniLink>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag={Link} to="/attend/about-reach/">
                About Reach
              </DropdownItem>
              <DropdownItem tag={Link} to="/attend/top-5-reasons/">
                Top 5 Reasons to Attend
              </DropdownItem>
              <DropdownItem tag={Link} to="/attend/convince/">
                Convince Your Manager
              </DropdownItem>
              {/*<DropdownItem tag={Link} to="/attend/faqs/">
                FAQs
              </DropdownItem>
              <DropdownItem tag={Link} to="/attend/health-safety/">
                Health & Safety
              </DropdownItem> */}
              <DropdownItem tag={Link} to="/attend/location/">
                Location
              </DropdownItem>
              {/* <DropdownItem tag={Link} to="/attend/pricing/">
                Pricing
              </DropdownItem> */}
              {/* <DropdownItem tag={Link} to="/attend/schedule/">
                Schedule
              </DropdownItem> */}
              <DropdownItem
                target="_blank"
                tag={Link}
                to="https://cvent.me/z74Vkl?RefId=reach24-registration"
              >
                Register
              </DropdownItem>
              <DropdownItem tag={Link} to="/attend/updates/">
                Updates
              </DropdownItem>
              {/* <DropdownItem tag={Link} to="/attend/watermark/">
                Watermark
              </DropdownItem> */}
            </DropdownMenu>
          </Dropdown>

          {/* Engage */}
          {/* <Dropdown
            nav
            inNavbar
            onMouseOver={() => updateIsOpen("engage")}
            onFocus={() => updateIsOpen("engage")}
            onMouseLeave={() => updateIsOpen("")}
            onBlur={() => updateIsOpen("")}
            toggle={() => updateIsOpen(isOpen === "engage" ? "" : "engage")}
            isOpen={isOpen === "engage" ? true : false}
          >
            <DropdownToggle nav tag={DropdownElement} className="parent">
              <AniLink
                className="nav-link"
                cover
                to="/engage/"
                bg="
              url(/images/XylemReach22_Logo_Wh_RGB.svg)
              center / 200px
              fixed 
              padding-box
              content-box
              #0085AD
              "
                duration={1.5}
              >
                Engage
              </AniLink>
            </DropdownToggle>
            <DropdownMenu> */}
          {/* <DropdownItem tag={Link} to="/engage/award/">
                Nominate a Reach Award
              </DropdownItem>
              <DropdownItem tag={Link} to="/engage/span-community">
                SPAN Community
              </DropdownItem>
              <DropdownItem tag={Link} to="/engage/speak">
                Speak at Reach
              </DropdownItem>*/}
          {/* <DropdownItem tag={Link} to="/engage/submit-abstract">
                Speak at Reach
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> */}

          {/* Agenda */}
          <Dropdown
            nav
            inNavbar
            onMouseOver={() => updateIsOpen("agenda")}
            onFocus={() => updateIsOpen("agenda")}
            onMouseLeave={() => updateIsOpen("")}
            onBlur={() => updateIsOpen("")}
            toggle={() => updateIsOpen(isOpen === "agenda" ? "" : "agenda")}
            isOpen={isOpen === "agenda" ? true : false}
          >
            <DropdownToggle nav tag={DropdownElement} className="parent">
              <AniLink
                className="nav-link"
                cover
                to="/agenda/"
                bg="
              url(/images/XylemReach22_Logo_Wh_RGB.svg)
              center / 200px
              no-repeat
              fixed
              padding-box
              content-box
              #0085AD
              "
                duration={1.5}
              >
                Agenda
              </AniLink>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem tag={Link} to="/agenda">
                Conference at a Glance
              </DropdownItem>
              <DropdownItem tag={Link} to="/agenda/sessions">
                Sessions
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {/* Learn */}
          {/* <Dropdown
            nav
            inNavbar
            onMouseOver={() => updateIsOpen("learn")}
            onFocus={() => updateIsOpen("learn")}
            onMouseLeave={() => updateIsOpen("")}
            onBlur={() => updateIsOpen("")}
            toggle={() => updateIsOpen(isOpen === "learn" ? "" : "learn")}
            isOpen={isOpen === "learn" ? true : false}
          >
            <DropdownToggle nav tag={DropdownElement} className="parent">
              <AniLink
                className="nav-link"
                cover
                to="/learn/"
                bg="
              url(/images/XylemReach22_Logo_Wh_RGB.svg)
              center / 200px
              no-repeat
              fixed
              padding-box
              content-box
              #0085AD
              "
                duration={1.5}
              >
                Learn
              </AniLink>
            </DropdownToggle>
            <DropdownMenu> */}
          {/*<DropdownItem tag={Link} to="/learn/keynote">
                Keynote
              </DropdownItem>
              <DropdownItem tag={Link} to="/learn/learning-labs">
                Learning Labs
              </DropdownItem>*/}
          {/* <DropdownItem tag={Link} to="/learn/sessions">
                Sessions
              </DropdownItem> */}
          {/*<DropdownItem tag={Link} to="/learn/span-community">
                SPAN Community
              </DropdownItem>*/}
          {/* </DropdownMenu>
          </Dropdown> */}

          {/* Sponsors */}
          <Dropdown
            nav
            inNavbar
            onMouseOver={() => updateIsOpen("sponsors")}
            onFocus={() => updateIsOpen("sponsors")}
            onMouseLeave={() => updateIsOpen("")}
            onBlur={() => updateIsOpen("")}
            toggle={() => updateIsOpen(isOpen === "sponsors" ? "" : "sponsors")}
            isOpen={isOpen === "sponsors" ? true : false}
          >
            <AniLink
              className="nav-link"
              cover
              to="/sponsors/"
              bg="
              url(/images/XylemReach22_Logo_Wh_RGB.svg)
              center / 200px
              no-repeat                
              fixed                    
              padding-box              
              content-box              
              #0085AD                    
              "
              duration={1.5}
            >
              Sponsors
            </AniLink>
          </Dropdown>
        </Nav>
      </div>
    </nav>
  );
};

export default Navigation;
